import cx from "classnames";
import { graphql, Link } from "gatsby";
import * as React from "react";

import { IconArrowLeft, IconArrowRight } from "react-heroicons-ui";

import { GQData, Post } from "../utils/gq-types";

import Card from "../components/Card";
import PostComponent from "../components/Post";
import OuterContainer from "../components/OuterContainer";
import SocialHeader from "../components/SocialHeader";

import { StandardProps } from "../utils/element-type";

interface PostDataProps {
  post: Post;
  previous?: Post;
  next?: Post;
}

interface Image {
  src: string;
  width: string;
  height: string;
  color: string;
}

interface PostPageProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>>,
    GQData<PostDataProps> {
  pageContext: {
    slug: string;
    imagesArray: Array<Image[]>;
  };
}

const PageLink: React.FunctionComponent<
  Pick<Post, "title" | "slug"> & {
    type?: "next" | "previous";
  }
> = ({ title, slug, type = "previous" }) => (
  <>
    <span className={cx("text-white", "text-xs", "font-body", "opacity-50")}>
      {type}
    </span>
    <Link
      to={slug}
      className={cx(
        "flex",
        "py-2",
        "justify-center",
        "items-center",
        "text-white",
        "font-display",
        "text-lg"
      )}
    >
      {type === "previous" && (
        <IconArrowLeft className={cx("fill-current", "mr-4")} />
      )}
      <span>{title}</span>
      {type === "next" && (
        <IconArrowRight className={cx("fill-current", "ml-4")} />
      )}
    </Link>
  </>
);

const PostPage: React.FunctionComponent<PostPageProps> = ({
  data: { post, next, previous },
  pageContext: { imagesArray },
}) => {
  return (
    <>
      <SocialHeader post={post} isSinglePost={true} />
      <PostComponent className={"mb-12"} {...post} />
      <OuterContainer className={cx("grid", "grid-flow-row", "gap-4")}>
        <>
          {imagesArray.map((rows, i) => (
            <div
              className={cx(
                "grid",
                "grid-flow-row",
                "md:grid-flow-col",
                "gap-4",
                "overflow-hidden"
              )}
              key={i}
            >
              {rows.map(({ width, height, src, color }, j) => (
                <Card
                  key={j}
                  backgroundColor={color}
                  imageSrc={src}
                  orientation={+width / +height < 1 ? "vertical" : "horizontal"}
                />
              ))}
            </div>
          ))}
          {(next || previous) && (
            <div
              className={cx(
                "flex",
                "my-12",
                "flex-col",
                "md:flex-row",
                "justify-between"
              )}
            >
              <div className={cx("flex", "flex-col", "items-start")}>
                {previous && <PageLink {...previous} />}
              </div>
              <div className={cx("flex", "flex-col", "items-end")}>
                {next && <PageLink type="next" {...next} />}
              </div>
            </div>
          )}
        </>
      </OuterContainer>
    </>
  );
};

export default PostPage;

export const postQuery = graphql`
  query($id: String!, $nextId: String, $previousId: String) {
    next: ghostPost(id: { eq: $previousId }) {
      title
      slug
      featureImage: feature_image
    }
    previous: ghostPost(id: { eq: $nextId }) {
      title
      slug
      featureImage: feature_image
    }
    post: ghostPost(id: { eq: $id }) {
      title
      slug
      publishedAt: published_at(fromNow: true)
      featureImage: feature_image
      # tags {
      #   id
      #   name
      # }
    }
  }
`;
